import { getStrapiURL } from './strapi';

export function getStrapiMedia(media: any) {
  const { url } = media?.data?.attributes || { url: '' };
  const imageUrl = url?.startsWith('/') ? getStrapiURL(url) : url;
  return imageUrl.replace(
    'cubet-website-storage-live.s3.us-west-2.amazonaws.com',
    'cubet-website-storage-live.s3-accelerate.amazonaws.com'
  );
}
