import Image from 'next/image';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation } from 'swiper';
import 'swiper/css';
import useWindowSize from '@/hooks/useWindowSize';
import { useEffect, useRef, useState } from 'react';
import { fetchAPI } from '@/utils/strapi';
import { getStrapiMedia } from '@/utils/media';
SwiperCore.use([Navigation]);
interface TestimonialProps {
  country?: string;
}
const Testimonial = ({country}:TestimonialProps) => {
  const [width] = useWindowSize();
  const swiperRef = useRef(null);
  const [testimonial, setTestimonial] = useState<any>([]);

  useEffect(() => {
    loadTestimonial();
  }, []);

  const loadTestimonial = async () => {
    const res = await fetchAPI('/testimonials', {
      populate: '*',
      'filters[Type]': 'Client',
    });

    setTestimonial(res.data);
  };

  const renderTestimonials = () => {
    const testimonials = [];
    let startIndex = 1;
    const itemsPerSwiper = width <= 767 ? 1 : 2;

    while (startIndex < testimonial?.length) {
      const testimonialsSlice = testimonial.slice(
        startIndex,
        startIndex + itemsPerSwiper
      );

      testimonials.push(
        <SwiperSlide key={startIndex}>
          {testimonialsSlice.map((testimonial: any, index: number) => (
            <div key={index}>
              <div className='box'>
                <p className='normal-text'>
                  {testimonial.attributes.Testimonial}
                </p>
                <div className='person'>
                  <div className='d-flex align-items-center'>
                    <Image
                      src={getStrapiMedia(testimonial.attributes.Photo)}
                      width={testimonial.attributes.Photo.data.attributes.width}
                      height={
                        testimonial.attributes.Photo.data.attributes.height
                      }
                      priority={false}
                      alt={
                        testimonial?.attributes?.Photo?.data?.attributes
                          ?.alternativeText || testimonial.attributes.ClientName
                      }
                      style={{ objectFit: 'cover' }}
                      className='img-fluid user-img'
                    />
                    <div>
                      <h6>{testimonial.attributes.ClientName}</h6>
                      <p>{testimonial.attributes.ClientDesignation}</p>
                    </div>
                  </div>
                  {width <= 767 && (
                    <div>
                      <Button
                        type='button'
                        className='prev-button me-2'
                        onClick={handlePrevButtonClick}
                      >
                        <Image
                          src={'/assets/icons/arrow_right_new.png'}
                          width={16}
                          height={16}
                          priority={false}
                          alt='user'
                          style={{ objectFit: 'contain' }}
                          className='img-fluid'
                        />
                      </Button>
                      <Button
                        type='button'
                        className='next-button ms-3'
                        onClick={handleNextButtonClick}
                      >
                        <Image
                          src='/assets/icons/arrow_right_new.png'
                          width={16}
                          height={16}
                          priority={false}
                          alt='user'
                          style={{ objectFit: 'contain' }}
                          className='img-fluid'
                        />
                      </Button>
                    </div>
                  )}
                </div>
              </div>
              {index !== testimonialsSlice.length - 1 && (
                <div className='line my-4 my-xxl-5'></div>
              )}
            </div>
          ))}
        </SwiperSlide>
      );

      startIndex += itemsPerSwiper;
    }

    return testimonials;
  };

  const handlePrevButtonClick = () => {
    (swiperRef.current as any)?.slidePrev();
  };

  const handleNextButtonClick = () => {
    (swiperRef.current as any)?.slideNext();
  };

  return (
    <div className='hear'>
      <Container>
        <Row className='gx-5-rem'>
          <Col xs={12}>
            <h2 className='heading mb-4-rem'>Hear What They Say</h2>
          </Col>
          <Col>
            <Image
              src={country ? `/assets/images/${country}-hear-us.jpg`:'/assets/images/hear1.jpg'}
              width={2856}
              height={1904}
              priority={false}
              alt='Hear what they say'
              style={{ objectFit: 'cover' }}
              className='img-fluid d-none d-md-block testi-main-img'
            />
            {testimonial?.length > 0 && (
              <>
                <div className='left-side mt-4'>
                  <Image
                    src='/assets/icons/quote.svg'
                    width={26}
                    height={23}
                    priority={false}
                    alt='quote'
                    style={{ objectFit: 'contain' }}
                    className='img-fluid mb-3'
                  />
                  <p>{testimonial[0].attributes.Testimonial}</p>
                </div>
                <div className='person person-first'>
                  <Image
                    src={getStrapiMedia(testimonial[0].attributes.Photo)}
                    width={
                      testimonial[0].attributes.Photo.data.attributes.width
                    }
                    height={
                      testimonial[0].attributes.Photo.data.attributes.height
                    }
                    priority={false}
                    alt={
                      testimonial[0]?.attributes?.Photo?.data?.attributes
                        ?.alternativeText ||
                      testimonial[0]?.attributes?.ClientName
                    }
                    style={{ objectFit: 'contain' }}
                    className='img-fluid user-img'
                  />
                  <div>
                    <h6>{testimonial[0].attributes.ClientName}</h6>
                    <p>{testimonial[0].attributes.ClientDesignation}</p>
                  </div>
                </div>
                <Image
                  src='/assets/images/hear1.jpg'
                  width={2856}
                  height={1904}
                  priority={false}
                  alt='Hear what they say'
                  style={{ objectFit: 'contain' }}
                  className='img-fluid d-block d-md-none img-second'
                />
              </>
            )}
          </Col>
          <Col md={6}>
            <Swiper
              slidesPerView={1}
              loop={true}
              navigation={{
                prevEl: '.prev-button',
                nextEl: '.next-button',
              }}
              onSwiper={(swiper) => ((swiperRef.current as any) = swiper)}
            >
              {renderTestimonials()}
            </Swiper>
            {width > 767 && (
              <div className='mt-md-5'>
                <Button
                  type='button'
                  className='prev-button me-2'
                  onClick={handlePrevButtonClick}
                >
                  <Image
                    src='/assets/icons/arrow_right_new.png'
                    width={16}
                    height={16}
                    priority={false}
                    alt='user'
                    style={{ objectFit: 'contain' }}
                    className='img-fluid'
                  />
                </Button>
                <Button
                  type='button'
                  className='next-button ms-3'
                  onClick={handleNextButtonClick}
                >
                  <Image
                    src='/assets/icons/arrow_right_new.png'
                    width={16}
                    height={16}
                    priority={false}
                    alt='user'
                    style={{ objectFit: 'contain' }}
                    className='img-fluid'
                  />
                </Button>
              </div>
            )}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Testimonial;
